import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COUPON_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import {
  AdminCouponDetail,
  CouponStatus,
} from "@sellernote/_shared/src/types/forwarding/coupon";

interface CouponDetailProps {}

const CouponDetail: React.FC<CouponDetailProps> = () => {
  const { handleSnackbarOpen } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data: couponData } = ADMIN_COUPON_QUERY.useGetAdminCouponDetail({
    couponId: Number(id),
  });

  const [startDate, setStartDate] = useState<Dayjs | null>(
    couponData ? dayjs(couponData.startDate) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    couponData ? dayjs(couponData.endDate) : null
  );

  const [formValues, setFormValues] = useState<Partial<AdminCouponDetail>>({
    campaignName: couponData?.campaignName || "",
    comment: couponData?.comment || "",
    status: couponData?.status || "normal",
    kind: couponData?.kind || "fixed",
    couponValidDays: couponData?.couponValidDays || 0,
    discountAmount: couponData?.discountAmount || 0,
    startDate: couponData?.startDate,
    endDate: couponData?.endDate,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await sendRequest({
        method: "put",
        path: `/coupon/campaign/${couponData?.id}`,
        apiType: "SHIPDA_ADMIN_API",
        data: {
          ...formValues,
          startDate: startDate?.format("YYYY-MM-DD"),
          endDate: endDate?.format("YYYY-MM-DD"),
        },
      });
      handleSnackbarOpen("쿠폰을 수정했습니다.");
      history.goBack();
    } catch (e) {
      handleSnackbarOpen("쿠폰 수정에 실패했습니다.", "error");
    }
  };

  if (!couponData) {
    return (
      <Layout breadcrumbs={["쿠폰"]} title={"쿠폰 상세"}>
        <Loading active />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["쿠폰"]} title={"쿠폰 상세"}>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
        }}
      >
        <TextField
          label="쿠폰명"
          name="campaignName"
          value={formValues.campaignName}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />

        <TextField
          label="쿠폰 설명"
          name="comment"
          value={formValues.comment}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>쿠폰 형식</InputLabel>
          <Select name="kind" value={formValues.kind} disabled>
            <MenuItem value="oneTime">일회용 쿠폰</MenuItem>
            <MenuItem value="fixed">일반 쿠폰</MenuItem>
            <MenuItem value="programatic">자동발급 쿠폰</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>상태</InputLabel>
          <Select
            name="status"
            value={formValues.status}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                status: e.target.value as CouponStatus,
              })
            }
          >
            <MenuItem value="normal">정상</MenuItem>
            <MenuItem value="expired">만료</MenuItem>
            <MenuItem value="terminated">종료</MenuItem>
            <MenuItem value="notWorking">정지</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="할인 금액"
          name="discountAmount"
          type="number"
          value={formValues.discountAmount}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" columnGap={1}>
            <DatePicker
              label="캠페인 시작일"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />

            <DatePicker
              label="캠페인 종료일"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>
        </LocalizationProvider>

        <TextField
          label="쿠폰 유효기간"
          name="couponValidDays"
          type="number"
          value={formValues.couponValidDays}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, width: 154 }}
        >
          쿠폰 수정
        </Button>
      </form>
    </Layout>
  );
};

export default CouponDetail;
