import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";

const ExcelDownloadModal = ({
  setShowExcelDownloadModal,
  showExcelDownloadModal,
}: {
  setShowExcelDownloadModal: React.Dispatch<React.SetStateAction<boolean>>;
  showExcelDownloadModal: boolean;
}) => {
  const [fetchParams, setFetchParams] = useState<{
    createdAtFromDate: string | undefined;
    createdAtToDate: string | undefined;
    remarketingFromDate: string | undefined;
    remarketingToDate: string | undefined;
  }>({
    createdAtFromDate: undefined,
    createdAtToDate: undefined,
    remarketingFromDate: undefined,
    remarketingToDate: undefined,
  });
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const [dateType, setDateType] = useState("create");
  const [isAutoFetch, setIsAutoFetch] = useState(false);

  const {
    data: excelData,
    newExcelList,
    isLoading,
  } = BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementExcelList(
    {
      createdAtFromDate: fetchParams.createdAtFromDate,
      createdAtToDate: fetchParams.createdAtToDate,
      remarketingFromDate: fetchParams.remarketingFromDate,
      remarketingToDate: fetchParams.remarketingToDate,
    },
    isAutoFetch
  );

  useEffect(() => {
    if (excelData) {
      csvLink?.current?.link.click();
      setIsAutoFetch(false);
      setFetchParams({
        createdAtFromDate: undefined,
        createdAtToDate: undefined,
        remarketingFromDate: undefined,
        remarketingToDate: undefined,
      });
    } else {
      return;
    }
  }, [excelData]);

  const handleDateType = (dateType: unknown) => {
    if (typeof dateType === "string") {
      setDateType(dateType);
      setIsAutoFetch(false);
      setFetchParams({
        createdAtFromDate: undefined,
        createdAtToDate: undefined,
        remarketingFromDate: undefined,
        remarketingToDate: undefined,
      });
    }
  };

  const startDateValue = () => {
    if (dateType === "remarketing") {
      return fetchParams.remarketingFromDate;
    } else {
      return fetchParams.createdAtFromDate;
    }
  };

  const endDateValue = () => {
    if (dateType === "remarketing") {
      return fetchParams.remarketingToDate;
    } else {
      return fetchParams.createdAtToDate;
    }
  };

  const handleChangeFromDate = (date?: string) => {
    if (!date) return;
    if (dateType === "remarketing") {
      setFetchParams({
        ...fetchParams,
        remarketingFromDate: date === "" ? undefined : date,
      });
    } else {
      setFetchParams({
        ...fetchParams,
        createdAtFromDate: date === "" ? undefined : date,
      });
    }
  };

  const handleChangeToDate = (date?: string) => {
    if (dateType === "remarketing") {
      setFetchParams({
        ...fetchParams,
        remarketingToDate: date === "" ? undefined : date,
      });
    } else {
      setFetchParams({
        ...fetchParams,
        createdAtToDate: date === "" ? undefined : date,
      });
    }
  };

  return (
    <Dialog
      open={showExcelDownloadModal}
      sx={{ height: 360 }}
      onClose={() => {
        setIsAutoFetch(false);
        setShowExcelDownloadModal(false);
      }}
    >
      <DialogContent sx={{ display: "flex", alignItems: "center" }}>
        <Select
          value={dateType}
          size="small"
          variant="outlined"
          onChange={(e) => handleDateType(e.target.value)}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            width: 150,
            mr: 2,
          }}
        >
          <MenuItem value="create">{"견적 생성일"}</MenuItem>
          <MenuItem value="remarketing">{"영업일"}</MenuItem>
        </Select>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="날짜선택"
            value={startDateValue() ? dayjs(startDateValue()) : null}
            onChange={(date) =>
              handleChangeFromDate(date?.format("YYYY/MM/DD"))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="날짜를 선택하세요"
              />
            )}
          />

          <Typography sx={{ mx: "8px" }}>~</Typography>

          <DatePicker
            label="날짜선택"
            value={endDateValue() ? dayjs(endDateValue()) : null}
            onChange={(date) => handleChangeToDate(date?.format("YYYY/MM/DD"))}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="날짜를 선택하세요"
              />
            )}
          />
        </LocalizationProvider>
      </DialogContent>

      <DialogActions sx={{ alignSelf: "center" }}>
        <Button
          color="primary"
          variant="contained"
          disabled={
            isLoading || dateType === "remarketing"
              ? fetchParams.remarketingFromDate === undefined ||
                fetchParams.remarketingToDate === undefined
              : fetchParams.createdAtFromDate === undefined ||
                fetchParams.createdAtToDate === undefined
          }
          sx={{ flexShrink: 0 }}
          onClick={() => {
            setIsAutoFetch(true);
          }}
        >
          엑셀 다운로드
        </Button>
        {newExcelList && (
          <CSVLink
            headers={[
              { label: "담당자", key: "managerName" },
              { label: "의뢰번호", key: "bidId" },
              { label: "의뢰 생성일", key: "createdAt" },
              { label: "현재 상태", key: "status" },
              { label: "회사명", key: "companyName" },
              { label: "운송유형", key: "serviceType" },
              { label: "INCOTERMS", key: "incoterms" },
              { label: "운송수단", key: "freightType" },
              { label: "물품상세", key: "productsInfo" },
              { label: "출발항", key: "startPort" },
              { label: "도착항", key: "endPort" },
              { label: "컨택횟수", key: "calls" },
              { label: "영업일시", key: "contactedAt" },
              { label: "미수주사유", key: "noProceedReason" },
              { label: "재영업일자", key: "remarketingDate" },
              { label: "진행여부", key: "inProgressFlag" },
              { label: "자동견적 여부", key: "autoEstimateFlag" },
              { label: "견적 생성일", key: "quotationCreatedAt" },
              { label: "취소 여부", key: "isExporterPartner" },
              { label: "수출자 정보입력", key: "initialProgressDate" },
            ]}
            ref={csvLink}
            data={newExcelList}
            filename="영업관리 리스트.csv"
            target="_blank"
          ></CSVLink>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExcelDownloadModal;
